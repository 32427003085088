// Generated by Framer (66beb72)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ugtbv9Yq1", "KSiA2L4eZ", "EG1c_p8TT"];

const serializationHash = "framer-Ye3wT"

const variantClassNames = {EG1c_p8TT: "framer-v-1qwldqt", KSiA2L4eZ: "framer-v-b6fn99", ugtbv9Yq1: "framer-v-11d1y5g"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.75, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Gradient Mobile": "EG1c_p8TT", Gradient: "KSiA2L4eZ", White: "ugtbv9Yq1"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ugtbv9Yq1"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ugtbv9Yq1", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-11d1y5g", className, classNames)} data-framer-name={"White"} layoutDependency={layoutDependency} layoutId={"ugtbv9Yq1"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{background: "linear-gradient(180deg, rgb(255, 255, 255) 0%, #e795b7 19.87530934478658%, #935893 45.361387919940114%, #224d88 100%)", borderBottomLeftRadius: 60, borderBottomRightRadius: 60, ...style}} variants={{EG1c_p8TT: {borderBottomLeftRadius: 30, borderBottomRightRadius: 30}}} {...addPropertyOverrides({EG1c_p8TT: {"data-framer-name": "Gradient Mobile"}, KSiA2L4eZ: {"data-framer-name": "Gradient"}}, baseVariant, gestureVariant)}><motion.div className={"framer-et7up3"} data-framer-name={"White"} layoutDependency={layoutDependency} layoutId={"d768W5VRG"} style={{backgroundColor: "rgb(8, 34, 97)", opacity: 1}} variants={{EG1c_p8TT: {opacity: 0}, KSiA2L4eZ: {opacity: 0}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Ye3wT.framer-i4c76e, .framer-Ye3wT .framer-i4c76e { display: block; }", ".framer-Ye3wT.framer-11d1y5g { height: 1417px; overflow: hidden; position: relative; width: 1200px; will-change: var(--framer-will-change-override, transform); }", ".framer-Ye3wT .framer-et7up3 { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1417
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"KSiA2L4eZ":{"layout":["fixed","fixed"]},"EG1c_p8TT":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerJ02pkliSg: React.ComponentType<Props> = withCSS(Component, css, "framer-Ye3wT") as typeof Component;
export default FramerJ02pkliSg;

FramerJ02pkliSg.displayName = "BG";

FramerJ02pkliSg.defaultProps = {height: 1417, width: 1200};

addPropertyControls(FramerJ02pkliSg, {variant: {options: ["ugtbv9Yq1", "KSiA2L4eZ", "EG1c_p8TT"], optionTitles: ["White", "Gradient", "Gradient Mobile"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerJ02pkliSg, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})